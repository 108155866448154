<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <!-- <a-layout-content
      style="background-color: #fff;line-height:60px; margin: 0;width:100%;height:60px "
    >
      <div class="Enter-top">
        <div>
          <div></div>
        </div>
      </div>
    </a-layout-content> -->
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <div>
          <div style="margin-bottom: 40px">
            <a-card title="最近访问" style="width: 100%">
              <div class="flow-d-row">
                <span
                  @click="toquick(item.path)"
                  v-for="(item, index) in list"
                  :key="index"
                  style="width: 16.66%"
                  class="bodys"
                  >{{ item.name }}</span
                >
              </div>
            </a-card>
          </div>

          <a-card title="全部流程" style="width: 100%">
            <div class="flow-d-row" style="width: 100%; flex-wrap: wrap">
              <div class="list flow-d-column">
                <div @click="torouter()" class="two-title">小程序</div>
                <span
                  v-show="permissionlist.indexOf('/xcx/config:修改') > -1"
                  @click="torouter('xcx', 'config')"
                  class="bodys"
                  >参数配置</span
                >
                <span
                  v-show="permissionlist.indexOf('/xcx/dashboard:新增') > -1"
                  @click="torouter('xcx', 'dashboard')"
                  class="bodys"
                  >新增模块</span
                >
              </div>
              <div class="list flow-d-column">
                <div class="two-title">招商实施</div>
                <span
                  v-show="permissionlist.indexOf('/business/client:新增') > -1"
                  @click="torouter('business', 'client')"
                  class="bodys"
                  >新增客户</span
                >
                <span
                  v-show="permissionlist.indexOf('/business/talk:新增') > -1"
                  @click="torouter('business', 'talk')"
                  class="bodys"
                  >新增洽谈</span
                >
                <span
                  v-show="
                    permissionlist.indexOf('/business/intention:新增') > -1
                  "
                  @click="torouter('business', 'intention')"
                  class="bodys"
                  >新增意向合同</span
                >
                <span
                  v-show="permissionlist.indexOf('/business/sign:新增') > -1"
                  @click="torouter('business', 'sign')"
                  class="bodys"
                  >新增正式合同</span
                >
              </div>
              <div class="list flow-d-column">
                <div class="two-title">资产管理</div>
                <span
                  v-show="permissionlist.indexOf('/property/project:新增') > -1"
                  @click="torouter('property', 'project')"
                  class="bodys"
                  >新增项目</span
                >
                <span
                  v-show="permissionlist.indexOf('/property/store:新增') > -1"
                  @click="torouter('property', 'store')"
                  class="bodys"
                  >新增铺位</span
                >
                <!-- <span class="bodys">铺位</span> -->
                <span
                  v-show="
                    permissionlist.indexOf('/property/storeAdjust:新增') > -1
                  "
                  @click="torouter('property', 'storeAdjust', 'h')"
                  class="bodys"
                  >铺位合并</span
                >
                <span
                  v-show="
                    permissionlist.indexOf('/property/storeAdjust:新增') > -1
                  "
                  @click="torouter('property', 'storeAdjust', 'c')"
                  class="bodys"
                  >拆分铺位</span
                >
                <span
                  v-show="permissionlist.indexOf('/property/dj:新增') > -1"
                  @click="torouter('property', 'dj')"
                  class="bodys"
                  >新增多经点位</span
                >
                <span
                  v-show="permissionlist.indexOf('/property/ad:新增') > -1"
                  @click="torouter('property', 'ad')"
                  class="bodys"
                  >新增广告位</span
                >
              </div>
              <div class="list flow-d-column">
                <div class="two-title">品牌库</div>
                <span
                  v-show="permissionlist.indexOf('/brands/native:新增') > -1"
                  @click="torouter('brands', 'native')"
                  class="bodys"
                  >新增本地品牌</span
                >
              </div>
              <div class="list flow-d-column">
                <div class="two-title">招商规划</div>
                <span
                  v-show="permissionlist.indexOf('/plan/plan:新增') > -1"
                  @click="torouter('plan', 'plan')"
                  class="bodys"
                  >新增方案</span
                >
              </div>
              <div class="list flow-d-column">
                <div class="two-title">巡检管理</div>
                <span
                  v-show="permissionlist.indexOf('/patrol/site:新增') > -1"
                  @click="torouter('patrol', 'site')"
                  class="bodys"
                  >新增点位</span
                >
                <span
                  v-show="permissionlist.indexOf('/patrol/line:新增') > -1"
                  @click="torouter('patrol', 'line')"
                  class="bodys"
                  >新增路线</span
                >
                <span
                  v-show="permissionlist.indexOf('/patrol/plan:新增') > -1"
                  @click="torouter('patrol', 'plan')"
                  class="bodys"
                  >新增计划</span
                >
                <span
                  v-show="permissionlist.indexOf('/patrol/plan:查询') > -1"
                  @click="torouter('patrol', 'plan', 'p')"
                  class="bodys"
                  >排版信息</span
                >
              </div>
            </div>
          </a-card>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
// import http from "../../../http";
export default {
  inject: ['reload'],
  data() {
    return {
      list: [],
      permissionlist: [],
    }
  },
  filters: {},
  methods: {
    toquick(item) {
      if (item.split('?')[1] == 'h') {
        this.$router.push({ path: item, query: { index: 'h' } })
        localStorage.setItem('selectedKeys', [item.split('?')[0]])
      } else if (item.split('?')[1] == 'c') {
        this.$router.push({ path: item, query: { index: 'c' } })
        localStorage.setItem('selectedKeys', [item.split('?')[0]])
      } else if (item.split('?')[1] == 'p') {
        this.$router.push({ path: item, query: { index: 'p' } })
        localStorage.setItem('selectedKeys', [item.split('?')[0]])
      } else {
        this.$router.push({ path: item, query: { visible: true } })
        localStorage.setItem('selectedKeys', [item])
      }
      let key = item.split('/')
      //   console.log(key);
      localStorage.setItem('openKeys', ['/' + key[1]])
      this.reload()
    },
    torouter(e, item, id) {
      //   console.log(id);
      let link = '/' + e + '/' + item
      if (id == undefined) {
        this.$router.push({
          path: '/' + e + '/' + item,
          query: { visible: 'true' },
        })
      } else {
        this.$router.push({
          path: '/' + e + '/' + item,
          query: { index: id },
        })
      }
      localStorage.setItem('openKeys', ['/' + e])
      localStorage.setItem('selectedKeys', [link])
      this.reload()
    },
  },
  mounted() {
    this.permissionlist = JSON.parse(
      localStorage.getItem('store-userlist')
    ).permissions
    let key = JSON.parse(localStorage.getItem('store-userlist')).id
    this.list = JSON.parse(localStorage.getItem(key))
    let arr = []
    for (var i = this.list.length - 1; i >= 0; i--) {
      arr.push(this.list[i])
    }
    this.list = arr
    this.list.forEach((item, index) => {
      if (item == '/xcx/config') {
        this.list[index] = { name: '参数配置', path: '/xcx/config' }
      } else if (item == '/xcx/dashboard') {
        this.list[index] = { name: '新增模块', path: '/xcx/dashboard' }
      } else if (item == '/welcome') {
        this.list[index] = { name: '首页', path: '/welcome' }
      } else if (item == '/audit/auditCenter') {
        this.list[index] = { name: '审批中心', path: '/audit/auditCenter' }
      } else if (item == '/audit/auditHistory') {
        this.list[index] = { name: '审批记录', path: '/audit/auditHistory' }
      } else if (item == '/business/client') {
        this.list[index] = { name: '新增客户', path: '/business/client' }
      } else if (item == '/business/talk') {
        this.list[index] = { name: '新增洽谈', path: '/business/talk' }
      } else if (item == '/business/intention') {
        this.list[index] = {
          name: '新增意向合同',
          path: '/business/intention',
        }
      } else if (item == '/business/sign') {
        this.list[index] = { name: '新增正式合同', path: '/business/sign' }
      } else if (item == '/property/project') {
        this.list[index] = { name: '新增项目', path: '/property/project' }
      } else if (item == '/property/store') {
        this.list[index] = { name: '新增铺位', path: '/property/store' }
      } else if (item == '/property/storeAdjust?h') {
        this.list[index] = {
          name: '铺位合并',
          path: '/property/storeAdjust?h',
        }
      } else if (item == '/property/storeAdjust?c') {
        this.list[index] = {
          name: '拆分铺位',
          path: '/property/storeAdjust?c',
        }
      } else if (item == '/property/dj ') {
        this.list[index] = { name: '新增多经点位', path: '/property/dj' }
      } else if (item == '/property/ad') {
        this.list[index] = { name: '新增广告位', path: '/property/ad' }
      } else if (item == '/brands/native') {
        this.list[index] = { name: '新增本地品牌', path: '/brands/native' }
      } else if (item == '/plan/plan') {
        this.list[index] = { name: '新增方案', path: '/plan/plan' }
      } else if (item == '/patrol/site') {
        this.list[index] = { name: '新增点位', path: '/patrol/site' }
      } else if (item == '/patrol/line') {
        this.list[index] = { name: '新增路线', path: '/patrol/line' }
      } else if (item == '/patrol/plan') {
        this.list[index] = { name: '新增计划', path: '/patrol/plan' }
      } else if (item == '/patrol/plan?p') {
        this.list[index] = {
          name: '排班计划',
          path: '/patrol/plan?p',
        }
      } else if (item == '/operate/bill') {
        this.list[index] = { name: '运营-账单管理', path: '/operate/bill' }
      } else if (item == '/operate/ibill') {
        this.list[index] = { name: '运营-意向金管理', path: '/operate/ibill' }
      } else if (item == '/operate/tenant') {
        this.list[index] = { name: '租户管理', path: '/operate/tenant' }
      } else if (item == '/finance/bill') {
        this.list[index] = { name: '财务-账单管理', path: '/finance/bill' }
      } else if (item == '/finance/flow') {
        this.list[index] = { name: '收支流水', path: '/finance/flow' }
      } else if (item == '/finance/ibill') {
        this.list[index] = { name: '财务-意向金管理', path: '/finance/ibill' }
      } else if (item == '/finance/zlreport') {
        this.list[index] = { name: '财务账龄报表', path: '/finance/zlreport' }
      }
    })

    // console.log(this.permissionlist);
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.two-title {
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 6px;
}
.bodys {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 6px;
  color: #739fca;
}
.bodys:hover {
  color: #1890ff;
}
.flow-d-row-bet {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flow-d-row {
  display: flex;
  flex-direction: row;
}
.flow-d-column {
  display: flex;
  flex-direction: column;
}
span {
  display: block;
  cursor: pointer;
}
.list {
  width: 16.66%;
  text-align: left;
  //   margin-block: 20px;
}
</style>
